import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "../../store";
import { employeeThunks } from "../../store/reducers/employee/employee.thunk";
import { useNavigate, useParams } from "react-router-dom";
import { projectThunks } from "../../store/reducers/projects/project.thunk";

const Assets = ({
  employeeData,
  moduleId,
  activeTab,
}) => {
  const [projectList, setProjectList] = useState([]);
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    onSubmit: async (values) => {
      await updateProjectDataApi(values)
    },
    initialValues: {
      assetId: employeeData.asset
        ? employeeData.asset.uid
        : null,
    },
  });


  useEffect(() => {
    projectListApi();
  }, []);

  const updateProjectDataApi = async (data) => {
    try {
      const response = await dispatch(
        employeeThunks.updateEmployee({ id, data, moduleId, activeTab })
      );
      if (employeeThunks.updateEmployee.fulfilled.match(response)) {
        return response;
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const projectListApi = async () => {
    debugger
    try {
      let data = {
        employeeId: '5d91b188-1b6e-4ef3-b6e1-1c3dfbfd1a65'
      }
      const response = await dispatch(projectThunks.employeeWiseAsset(data));

      if (projectThunks.employeeWiseAsset.fulfilled.match(response)) {
        debugger
        console.log('Works');
        
        if (response.payload) {
          setProjectList(response.payload);
        }
      }
    } catch (error) {
      console.error("Error fetching project list:", error);
    }
  };

  return (
    <form
      className="form-horizontal"
      id="personal_info"
      onSubmit={handleSubmit}
    >
      <div className="card-body">

        <div className="form-group row">
          <label className="col-sm-4 col-md-3">Assets</label>
          <div className="col-sm-5  col-md-5">
            <select
              className="form-control"
              name="assetId"
              id="assetId"
              onChange={handleChange}
            >
              <option>Select</option>
              {projectList.map((data) => {
                return (
                  <option
                    selected={data.uid === values.assetId}
                    value={data.uid}
                  >
                    {data.projectName} {" - "} {data.projectNumber}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

      </div>
      <div className="d-flex align-items-center justify-content-center">
        <button
          type="submit"
          className="btn btn-primary"
          style={{ padding: "8px 20px" }}
        >
          <i className="fa fa-floppy-o" style={{ marginRight: "8px" }} />
          Update
        </button>
      </div>
    </form>
  );
};

export default Assets;
