import { createTheme } from "@mui/material";
import { ThemeColor } from "../../constants/themeColorConstant";

export const customStyles = `
.MuiPopover-paper {
  min-width: 180px; /* Adjust the width as per your requirement */
  padding-left: 6px 8px; /* Adjust the padding as per your requirement */
}
.css-ubpweo-MuiPaper-root-MuiPopover-paper{
    margin-right:20px;
    left:100px;
}
  .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
    display:flex;
    align-items:baseline;
  }`;

export const getMuiTheme = () =>
  createTheme({
    components: {
      MUIDataTableHeadCell: {
        toolButton: {
          justifyContent: "center",
        },
      },
      // MuiButtonBase: {
      //   styleOverrides: {
      //     span: {
      //       padding: "opx",
      //     },
      //   },
      // },
      MuiTableCell: {
        styleOverrides: {
          head: {
            backgroundColor: `${ThemeColor.BLUE} !important`,
            whiteSpace: "pre-line",
            maxWidth: "300px",
            textAlign: "left !important",
            justifyContent: "left !important",
            border: "10px",
            padding : '0px !important'
          },
          root: {
            fontSize: "14px",
            whiteSpace: "nowrap",
            textAlign : 'left !important',
            justifyContent: 'left !important'
          },
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            "& .MuiToolbar-root": {
              backgroundColor: ThemeColor.WHITE,
            },
          },
        },
      },
      MUIDataTableBodyRow: {
        styleOverrides: {
          root: {
            "&:nth-of-type(odd)": {
              backgroundColor: ThemeColor.GREY,
            },
            "&:nth-of-type(even)": {
              backgroundColor: ThemeColor.WHITE,
            },
          },
        },
      },
      MUIDataTableToolbar: {
        styleOverrides: {
          titleText: {
            fontSize: "22px",
            fontWeight: 500,
            color: ThemeColor.EASTERNBLUE,
          },
        },
      },
    },
  });

export const buttonStyle = {
  ":hover": {
    backgroundColor: ThemeColor.BLUE,
    color: ThemeColor.WHITE,
  },
};

export const UpdateButton = {
  ":hover": {
    backgroundColor: ThemeColor.WHITE,
    borderColor: ThemeColor.BLUE,
    color: ThemeColor.BLUE,
  },
};
