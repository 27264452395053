import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";
import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { projectCostingThunk } from "../../store/reducers/projectCosting/projectCosting.thunk";



const ProjectCosting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [projectCostingData, setprojectCostingData] = useState([]);
  const [option, setOption] = useState({});

  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;



  const columns = [
    {
      name: "projectName",
      label: "Project Name",
    },
    {
      name: "lpoValue",
      label: "LPO Value",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "projReservedAmount",
      label: "project Reserved Amount (Project Value)",
     
    },
    {
      name: "totalProjectedExpense",
      label: "total Projected Expense (Subcontract Value)",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "totalSupplierExpense",
      label: "total Supplier Expense",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value.toFixed(2);
        },
      },
    },
    {
      name: "totalSalaryExpense",
      label: "total Salary Expense",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value.toFixed(2);
        },
      },
    },
    {
      name: "totalActualExpense",
      label: "total Actual Expense",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value.toFixed(2);
        },
      },
    },
    {
      name: "differenceAmount",
      label: "Difference Amount",
      options: {
        filter: false,
        sort: true,
        setCellProps: (value, tableMeta) => {
          let projectedExpense = projectCostingData[tableMeta].projReservedAmount;
          let per = (value / projectedExpense) * 100;
          let color = 'red';
          if (per >= 20) {
            color = 'green';
          }
          else if (per > 10 && per < 20) {
            color = 'greenyellow';
          }
          else if (per > 5 && per < 10) {
            color = 'yellow';
          }
          else if (per > 0 && per < 5) {
            color = 'salmon';
          }
          return {

            style: {
              backgroundColor: color,
            },
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return value.toFixed(2);
        },

      },
    },
    {
      name: "currentStatus",
      label: "Status",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    projectExpenseListApi()
  }, []);

  const projectExpenseListApi = async (page = 1, rowsPerPage = 10, searchText = '', name = '', order = '') => {
    try {
      const response = await dispatch(projectCostingThunk.projectCostingReportList({ moduleId, page, rowsPerPage, searchText, name, order }));
      if (projectCostingThunk.projectCostingReportList.fulfilled.match(response)) {
        if (response.payload) {
          const options = {
            count: response.payload.totalRecords,
            page: response.payload.currentPage - 1, // Zero-based index
            rowsPerPage: response.payload.recordPerPage,
          }
          setOption(options)
          setprojectCostingData(response.payload.data);
          return response.payload
        }
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const breadcrumb = {
    mainHeader: "Project Costing Report",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Project Costing Report",
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header ">
            <p
              className="card-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: ThemeColor.EASTERNBLUE,
              }}
            >
              Project Costing Report
            </p>
          </div>
          <Datatable
            columns={columns}
            data={projectCostingData}
            options={option}
            api={projectExpenseListApi}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default ProjectCosting;
