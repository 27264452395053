import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiCall } from "../../../utils/api";
import { HTTP_METHODS } from "../../../constants/methodsConstant";
import { ToastType, setToastContent } from "../toast/toast.slice";
import { ROUTES } from "../../../constants/routesConstant";

export const projectListData = createAsyncThunk(
  "project/list",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        `${ROUTES.GET_PROJECT}?page=${inputData.page}&limit=${inputData.rowsPerPage}&search=${inputData.searchText}&sort=${inputData.name}&order=${inputData.order}`,
        {},
        inputData.moduleId
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const projectDetails = createAsyncThunk(
  "project/Details",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        ROUTES.GET_PROJECT + `/${inputData.id}`,
        {},
        inputData.moduleId
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const addProject = createAsyncThunk(
  "project/add",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.POST,
        ROUTES.GET_PROJECT,
        inputData.data,
        inputData.moduleId
      );
      if (response?.isSuccess) {
        dispatch(
          setToastContent({
            type: ToastType.success,
            message: response.message,
          })
        );
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const updateProject = createAsyncThunk(
  "project/update",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.PATCH,
        ROUTES.GET_PROJECT + `/${inputData.id}`,
        inputData.data,
        inputData.moduleId
      );
      if (response?.isSuccess) {
        dispatch(
          setToastContent({
            type: ToastType.success,
            message: response.message,
          })
        );
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const projectStatusList = createAsyncThunk(
  "project/projectStatusList",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        ROUTES.GET_PROJECT_STATUS,
        {},
        inputData
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const projectTypesList = createAsyncThunk(
  "project/projectTypesList",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        ROUTES.GET_PROJECT_TYPES,
        {},
        inputData
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const projectScopesList = createAsyncThunk(
  "project/projectScopesList",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        ROUTES.GET_PROJECT_SCOPE,
        {},
        inputData
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const projectList = createAsyncThunk(
  "project/projectList",
  async (inputData, { dispatch }) => {
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        ROUTES.PROJECT_LIST,
        {},
        inputData.moduleId
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);
export const employeeWiseProjectList = createAsyncThunk(
  "project/employeeWiseProjects",
  async (inputData, { dispatch }) => {
    debugger
    console.log(inputData)
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        `${ROUTES.GET_EMPLOYEE_PROJECT}/${inputData.employeeId}/projects`,
        {},
        inputData
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const employeeWiseAsset = createAsyncThunk(
  "project/employeeWiseProjects",
  async (inputData, { dispatch }) => {
    console.log(inputData)
    try {
      const response = await ApiCall(
        HTTP_METHODS.GET,
        `${ROUTES.GET_EMPLOYEE_PROJECT}/get-unassigned-assets`,
        {},
        inputData
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const employeeProjectDelete = createAsyncThunk(
  "attedance/remove",
  async (inputData, { dispatch }) => {
    console.log(inputData)
    try {
      const response = await ApiCall(
        HTTP_METHODS.POST,
        ROUTES.DELETE_ATTENDANCE,
        inputData.data,
        inputData.moduleId
      );
      if (response?.isSuccess) {
        return response.payload;
      } else {
        dispatch(
          setToastContent({
            type: ToastType.error,
            message: response.message,
          })
        );
      }
    } catch (error) {
      throw new Error("An error occurred");
    }
  }
);

export const projectThunks = {
  projectListData,
  projectList,
  projectDetails,
  updateProject,
  addProject,
  projectStatusList,
  projectScopesList,
  employeeWiseProjectList,
  projectTypesList,
  employeeProjectDelete
};
