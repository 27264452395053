import React, { useEffect, useState } from "react";
import Datatable from "../../components/datatable";

import { useDispatch } from "../../store";
import { ThemeColor } from "../../constants/themeColorConstant";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { reportThunk } from "../../store/reducers/report/report.thunk";


const ProjectSalaryExpense = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [reportData, setReportData] = useState([]);
  const [option, setOption] = useState({});
  const [selectedMonthYear, setSelectedMonthYear] = useState("");

  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");
  const moduleName = pathnameSegments[1];

  const allowedModules = JSON.parse(localStorage.getItem("allowedModules"));
  const selectedModule = allowedModules.find(
    (module) => module.slug === moduleName
  );

  const moduleId = selectedModule.uid;




  const handleMonthYearChange = async (event) => {
    const selectedDate = event.target.value;
    setSelectedMonthYear(selectedDate);
  };

  const columns = [
    {
      name: "projectCode",
      label: "Project Code",
    },
    {
      name: "projectName",
      label: "Project",

    },
    {
      name: "expense",
      label: "Expense",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value.toFixed(2);
        },
      },
    },
  ];


  const getProjectExpense = async (
    page = 1,
    rowsPerPage = 10,
    searchText = "",
    name = "",
    order = ""
  ) => {
    try {
      if (selectedMonthYear) {
        const [year, month] = selectedMonthYear.split('-');
        const formattedDate = `${month}/${year}`;
        const response = await dispatch(reportThunk.projectSalaryList({
          month: formattedDate, moduleId, page, rowsPerPage, searchText, name, order,
        })
        );
        if (reportThunk.projectSalaryList.fulfilled.match(response)) {
          if (response.payload) {
            const options = {
              count: response.payload.totalRecords,
              page: response.payload.currentPage - 1, // Zero-based index
              rowsPerPage: response.payload.recordsPerPage,
            };
            setOption(options);
            setReportData(response.payload.data);
            return response.payload;
          }
        }
      }
      else {
        setReportData()
        setOption()
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };



  useEffect(() => {
    getProjectExpense();
  }, [selectedMonthYear]);

  const breadcrumb = {
    mainHeader: "Project Salary Expense Report",
    subHeader: "Home",
    subHeaderLink: "/dashboard",
    subHeader1: "Report",
    subHeader1Link: null,
    subHeader2: null,
    subHeader2Link: null,
  };

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <div className="card">
        <div className="card-body">
          <div className="card-header ">

            <div className="form-group row d-flex justify-content-center">
              <label className="col-sm-2 col-md-2">
                Select Month
              </label>
              <div className="col-sm-4  col-md-4">
                <input type="month"
                  name="month"
                  id="month"

                  className="form-control"
                  value={selectedMonthYear}
                  onChange={handleMonthYearChange}
                />
              </div>
            </div>
          </div>

          <Datatable
            columns={columns}
            data={reportData}
            options={option}
            api={getProjectExpense}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default ProjectSalaryExpense;
